import { Box, Grid } from "@mui/material"
import React from "react"
import { DevelopmentList } from "./DevelopmentList"
import top from "./img/top.png"

export const Home = () => {
    return (
        <Box>
            <Grid container alignItems="center" justifyContent={"center"}>
                <Grid item sm={12} md={8} style={{ backgroundColor: "#fff" }}>
                    <Box>
                        <Top />
                        <DevelopmentList />
                    </Box>
                </Grid>
            </Grid>
        </Box >
    )
}

const Top = () => {
    return (
        <Box>
            <Grid container >
                <Grid item sm={12} md={5}>
                    <img src={top} alt="top" width="100%" />
                </Grid>
                <Grid item sm={12} md={7} style={{ position: "relative" }}>
                    <Box style={{ width: "100%" }}>
                        <button style={{ color: "#707070", fontSize: "25px", marginInline: "20px", marginBlockStart: "20px" }}>About</button>
                        <a href="https://docs.google.com/forms/d/e/1FAIpQLSc3USPu6utUR3epLFtgG_NPXQ-yrJFmDpDFps_ApyWRq5qegg/viewform" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "#707070", fontSize: "25px", marginInline: "20px", marginBlockStart: "20px" }}>Contact
                        </a>
                        <a href="https://blog.tb-works.com/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "#707070", fontSize: "25px", marginInline: "20px", marginBlockStart: "20px" }}>Blog
                        </a>
                    </Box>

                    <Box style={{ position: "absolute", bottom: "100px", width: "100%" }} >
                        <div style={{ color: "#404040", textShadow: "1px 1px 0 #FFF, -1px -1px 0 #FFF, -1px 1px 0 #FFF, 1px -1px 0 #FFF, 0px 1px 0 #FFF, 0-1px 0 #FFF, -1px 0 0 #FFF, 1px 0 0 #FFF", fontSize: "3rem", width: "100%", textAlign: "left", marginInlineStart: "52px" }}>TBWorks</div>
                        <div style={{ color: "#707070", textShadow: "1px 1px 0 #FFF, -1px -1px 0 #FFF, -1px 1px 0 #FFF, 1px -1px 0 #FFF, 0px 1px 0 #FFF, 0-1px 0 #FFF, -1px 0 0 #FFF, 1px 0 0 #FFF", fontSize: "1.5rem", width: "100%", textAlign: "left", marginInlineStart: "52px" }}>Development<br />For<br />All People</div>
                    </Box>
                </Grid>
            </Grid>

        </Box >
    )
}