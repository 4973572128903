import { Box } from "@mui/material";
import React from "react";


const style = {
    color: "#ebf6f7",
    backgroundColor: "#0f2350",
    width: "100%",
}
export const Footer = () => {
    return (
        <Box sx={style}>
            copyright © 2022 tbworks.com
        </Box>
    )
};