import './App.css';
import { Home } from './Home.tsx'
import { Footer } from './Footer.tsx'

function App() {
  return (
    <div className="App">
      <Home />
      <Footer />
    </div>
  );
}

export default App;

